import request from '@/utils/request'

export function getAllVideo(data) {
	return request({
		url: '/getAllVideo',
		method: 'post',
		data
	})
}

export function getToken(data) {
	return request({
		url: '/getToken',
		method: 'post',
		data
	})
}

export function feedback(data) {
	return request({
		url: '/feedback',
		method: 'post',
		data
	})
}
